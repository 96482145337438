<template>
  <v-app>
    <vue-particles
      color="#240b36"
      :particleOpacity="0.7"
      :particlesNumber="50"
      shapeType="star"
      :particleSize="4"
      linesColor="#c31432"
      :linesWidth="2"
      :lineLinked="true"
      :lineOpacity="0.4"
      :linesDistance="150"
      :moveSpeed="3"
      :hoverEffect="true"
      hoverMode="grab"
      :clickEffect="true"
      clickMode="push"
    >
    </vue-particles>
    <v-container class="container">
      <div>
        <v-app-bar class="bar1" :elevation="11">
          <v-row align="center" class="d-flex align-center">
            <v-col :cols="4" align="center">
              <v-img
                src="../assets/images/logo.png"
                class="logo"
                alt="kereta-sewa-seremban"
                @click="$router.push({ path: '/' })"
              />
            </v-col>
            <v-col :cols="4">
              <ul
                class="nav-items"
                style="flex-direction: row !important; display: flex !important"
              >
                <li>
                  <a
                    href="https://www.facebook.com/profile.php?id=100095115500120"
                    target="_blank"
                  >
                    <v-icon
                      aria-label="facebook"
                      size="40"
                      style="
                      text-decoration: none !important;
                          background-image: linear-gradient(to right, #ad5389, #3c1053);
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
                      class="btnWa"
                      @click="trackButtonClick('FacebookGroup')"
                      >mdi-facebook</v-icon
                    >
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/jrv_keretasewa/"
                    target="_blank"
                  >
                    <v-icon
                      aria-label="instagram"
                      size="40"
                      style="
                      text-decoration: none !important;
                          background-image: linear-gradient(to right, #ad5389, #3c1053);
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
                      class="btnWa"
                      @click="trackButtonClick('InstagramGroup')"
                      >mdi-instagram</v-icon
                    >
                  </a>
                </li>
                <li>
                  <a
                    href="https://chat.whatsapp.com/DVZXvnBiDqS0Z9jkHwL9yT"
                    target="_blank"
                  >
                    <v-icon
                      aria-label="whatsapp"
                      size="40"
                      style="
                      text-decoration: none !important;
                          background-image: linear-gradient(to right, #ad5389, #3c1053);
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
                      class="btnWa"
                      @click="trackButtonClick('WhatsappGroup')"
                      >mdi-whatsapp</v-icon
                    >
                  </a>
                </li>
                <li>
                  <a href="https://t.me/+ILGvKb39imRkMDNl" target="_blank">
                    <v-icon
                      aria-label="telegram"
                      size="40"
                      style="
                      text-decoration: none !important;
                          background-image: linear-gradient(to right, #ad5389, #3c1053);
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
                      class="btnWa"
                      @click="trackButtonClick('TelegramGroup')"
                      >mdi-telegram</v-icon
                    >
                  </a>
                </li>
              </ul>
              <br />
              <v-card-text class="text-center mx-auto title">
                KERETA SEWA SEREMBAN JRV
              </v-card-text>
            </v-col>
            <v-col :cols="4" align="center" style="max-width: 100% !important">
              <v-btn
                v-if="showAdminButton"
                class="justify-end btnWa"
                @click="$router.push({ path: '/home' })"
                ><v-icon
                  style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053);
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
                >
                  mdi-home-circle-outline mdi-18px </v-icon
                >Admin</v-btn
              >
              <v-card-text class="username" v-if="userProfile.name">
                {{ userProfile.name }}
              </v-card-text>

              <v-btn
                icon
                @click="account()"
                v-if="userProfile.name"
                class="btnWa"
              >
                <v-icon
                  style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053);
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
                  >mdi-account-circle-outline</v-icon
                >
              </v-btn>

              <v-btn
                icon
                @click="logout()"
                v-if="userProfile.name"
                class="btnWa"
              >
                <v-icon
                  style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053);
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
                  >mdi-logout-variant</v-icon
                >
              </v-btn>

              <div v-else>
                <v-btn
                  class="justify-end btnWa"
                  @click="$router.push({ path: '/login' })"
                  ><v-icon
                    style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053) !important;
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
                    alt="WhatsApp icon"
                    aria-label="Contact JRV-Car-Rental-Seremban on WhatsApp"
                    >mdi-login</v-icon
                  ><v-card-text class="text-center header2"
                    >Login / Register</v-card-text
                  ></v-btn
                >
              </div>
            </v-col>
          </v-row>
        </v-app-bar>
      </div>
      <v-card class="grid" :elevation="11">
        <div v-if="userProfile.name">
          <v-card-text class="text-center mx-auto header"
            >Welcome Mr/Ms {{ userProfile.name }} to JRV Car Rental Seremban ,
            you may view our cars below and connect with us on whatsapp to make
            reservations.</v-card-text
          >
          <v-card-text class="text-center mx-auto header"
            >Selamat Datang Encik/Cik {{ userProfile.name }} ke Kereta Sewa
            Seremban JRV, anda boleh lihat kereta kami di bawah dan berhubung dengan
            kami di whatsapp untuk membuat tempahan.</v-card-text
          >
        </div>
        <div v-else>
          <v-card-text class="text-center mx-auto header"
            >Welcome to JRV Car Rental Seremban, you may view our cars below and
            connect with us on whatsapp to make reservations.</v-card-text
          >
          <v-card-text class="text-center mx-auto header"
            >Selamat Datang ke Kereta Sewa Seremban JRV, anda boleh lihat kereta
            kami di bawah dan berhubung dengan kami di whatsapp untuk membuat
            tempahan.</v-card-text
          >
        </div>

        <div class="row-container">
          <v-card-text class="text-center header"
            >View available cars today
            <v-btn
              class="justify-end"
              @click="$router.push({ path: '/catalogue' })"
              target="_blank"
              aria-label="WhatsApp"
              ><v-icon
                style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053) !important;
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
                alt="WhatsApp icon"
                >mdi-calendar-check</v-icon
              >
              <v-card-text class="text-center header2"
                >Available Cars</v-card-text
              ></v-btn
            >
            Or connect with us on whatsapp for further inquiries.
            <v-btn
              class="justify-end"
              :href="`https://wa.me/138845477?text=I%20want%20to%20know%20more%20about%20your%20rental%20services%20JRV%20Seremban`"
              target="_blank"
              aria-label="Whatsapp"
              ><v-icon
                style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053) !important;
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
                alt="WhatsApp icon"
                >mdi-whatsapp</v-icon
              >
              <v-card-text class="text-center header2"
                >Chat Now</v-card-text
              ></v-btn
            ></v-card-text
          >
        </div>
      </v-card>
      <v-card
        class="grid"
        :elevation="11"
        style="
          opacity: 1 !important;
          background: rgba(249, 236, 224, 0.59) !important !important;
        "
      >
        <v-tabs
          v-model="tab1"
          centered
          grow
          next-icon="mdi-arrow-right-bold-box-outline"
          prev-icon="mdi-arrow-left-bold-box-outline"
          show-arrows
        >
          <v-tab
            ><v-card-text class="text-center mx-auto header"
              >Our Cars</v-card-text
            ></v-tab
          >
          <v-tab
            ><v-card-text class="text-center mx-auto header"
              >Customer Reviews</v-card-text
            ></v-tab
          >
          <v-tab
            ><v-card-text class="text-center mx-auto header"
              >Find Us</v-card-text
            ></v-tab
          >
        </v-tabs>
        <v-tabs-items v-model="tab1" touchless>
          <v-tab-item style="margin-top: 20px !important">
            <v-tabs
              v-model="tab"
              centered
              next-icon="mdi-arrow-right-bold-box-outline"
              prev-icon="mdi-arrow-left-bold-box-outline"
              show-arrows
              grow
            >
              <v-tab
                :href="`#available`"
                @click="trackButtonClick('CategoryClick', 'AVAILABLE TODAY')"
              >
                <v-card-text class="text-center mx-auto header"
                  >AVAILABLE TODAY</v-card-text
                >
              </v-tab>
              <v-tab
                v-for="(categoryCars, categoryName) in groupedCars"
                :key="categoryName"
                :value="categoryName"
                :href="`#${categoryName}`"
                @change="trackButtonClick('CategoryClick', categoryName)"
              >
                <v-card-text class="text-center mx-auto header">{{
                  categoryName
                }}</v-card-text>
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab" touchless>
              <v-tab-item value="available">
                <v-container>
                  <v-card
                    elevation="5"
                    class="grid4"
                    v-for="car in availableCars"
                    :key="car._id"
                    style="padding-bottom: 1%; margin-top: 20px !important"
                  >
                    <!-- <v-card class="grid5" :elevation="5" shaped> -->
                    <v-row
                      class="d-flex align-center justify-center"
                      style="margin-top: 20px !important"
                    >
                      <div class="text-center mx-auto text4">
                        {{ car.make }} {{ car.model }}
                      </div>
                    </v-row>
                    <!-- </v-card> -->
                    <!-- <v-spacer></v-spacer><v-spacer></v-spacer> -->
                    <v-card
                      class="grid4"
                      :elevation="5"
                      shaped
                      style="
                        margin-top: 20px !important ;
                        border-radius: inherit !important;
                      "
                    >
                      <v-row class="d-flex align-center justify-center">
                        <v-btn
                          aria-label="Whatsapp"
                          class="justify-end btnwa2"
                          :href="`https://wa.me/126565477?text=I'm%20interested%20in%20your%20${car.make}%20${car.model}%20for%20rental%20from%20JRV%20Seremban`"
                          target="_blank"
                          @click="
                            trackButtonClick('WhatsappMessageInactive', car)
                          "
                          ><v-icon
                            style="color: white !important"
                            alt="WhatsApp icon"
                            aria-label="Contact JRV-Car-Rental-Seremban on WhatsApp"
                            >mdi-whatsapp</v-icon
                          ><v-card-text class="text-center header3"
                            >Book Now</v-card-text
                          ></v-btn
                        >
                      </v-row>
                    </v-card>
                    <carousel-3d>
                      <slide
                        v-for="(image, i) in car.imageUrl"
                        :index="i"
                        :key="i"
                        style="
                          max-width: 250px !important;
                          max-height: 250px !important;
                        "
                      >
                        <template
                          slot-scope="{
                            index,
                            isCurrent,
                            leftIndex,
                            rightIndex,
                          }"
                        >
                          <img
                            :data-index="index"
                            :class="{
                              current: isCurrent,
                              onLeft: leftIndex >= 0,
                              onRight: rightIndex >= 0,
                            }"
                            :src="image.url"
                            width="250px"
                            height="250px"
                          />
                        </template>
                      </slide>
                    </carousel-3d>
                    <v-row align="center" class="d-flex align-center">
                      <v-col>
                        <v-card-text
                          class="d-flex align-center justify-center price"
                        >
                          <textra
                            :data="car.pricePerDay"
                            :timer="3"
                            filter="left-right"
                            infinite
                          />
                        </v-card-text>
                      </v-col>
                    </v-row>
                    <v-row align="center" class="d-flex align-center">
                      <v-col>
                        <v-card-text
                          class="d-flex align-center justify-center price2"
                        >
                          <textra
                            :data="car.pricePer3Days"
                            :timer="3"
                            filter="left-right"
                            infinite
                          />
                        </v-card-text>
                      </v-col>
                    </v-row>
                    <v-row align="center" class="d-flex align-center">
                      <v-col>
                        <v-card-text
                          class="d-flex align-center justify-center price3"
                        >
                          <textra
                            :data="car.pricePerWeek"
                            :timer="3"
                            filter="left-right"
                            infinite
                          />
                        </v-card-text>
                      </v-col>
                    </v-row>
                    <v-row align="center" class="d-flex align-center">
                      <v-col>
                        <v-card-text
                          class="d-flex align-center justify-center price4"
                        >
                          <textra
                            :data="car.pricePerMonth"
                            :timer="3"
                            filter="left-right"
                            infinite
                          />
                        </v-card-text>
                      </v-col>
                    </v-row>
                    <v-row align="center" class="d-flex align-center">
                      <v-col class="d-flex align-center mx-auto">
                        <v-card-text class="text-center mx-auto color"
                          >Available Colors :
                        </v-card-text>
                        <v-card
                          min-height="20px"
                          min-width="20px"
                          :style="{ backgroundColor: car.color }"
                          style="
                            margin-left: 2px !important;
                            margin-right: 2px !important;
                          "
                        >
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row
                      align="center"
                      class="d-flex align-center"
                      style="margin-right: -12px !important"
                    >
                      <!-- <div class="d-flex align-items-center justify-content-center"> -->
                      <div v-if="car.features[0].type">
                        <v-col class="d-flex align-center mx-auto">
                          <div v-if="car.features[0].type == 'SEDAN'">
                            <v-img
                              src="../assets/images/sedan.png"
                              style="margin-left: 1% !important"
                              width="40px"
                            ></v-img>
                          </div>
                          <div v-if="car.features[0].type == 'HATCHBACK'">
                            <v-img
                              src="../assets/images/hatchback.png"
                              style="margin-left: 1% !important"
                              width="40px"
                            ></v-img>
                          </div>
                          <div v-if="car.features[0].type == 'SUV'">
                            <v-img
                              src="../assets/images/suv.png"
                              style="margin-left: 1% !important"
                              width="40px"
                            ></v-img>
                          </div>
                          <div v-if="car.features[0].type == 'MPV'">
                            <v-img
                              src="../assets/images/minivan.png"
                              style="margin-left: 1% !important"
                              width="40px"
                            ></v-img>
                          </div>
                        </v-col>
                      </div>
                      <div v-if="car.features[0].transmission">
                        <v-col class="d-flex align-center mx-auto">
                          <div v-if="car.features[0].transmission == 'Auto'">
                            <v-img
                              src="../assets/images/auto.png"
                              style="margin-left: 1% !important"
                              width="30px"
                            ></v-img>
                          </div>
                          <div v-if="car.features[0].transmission == 'Manual'">
                            <v-img
                              src="../assets/images/manual.png"
                              style="margin-left: 1% !important"
                              width="30px"
                            ></v-img>
                          </div>
                        </v-col>
                      </div>
                      <!-- <div v-if="car.features[0].seats">
                        <div v-if="car.features[0].seats == '5'">
                          <Icon
                            icon="iconamoon:number-5-circle"
                            width="40"
                            style="color: #ad5389"
                          />
                        </div>
                        <div v-if="car.features[0].seats == '7'">
                          <Icon
                            icon="iconamoon:number-7-circle"
                            width="40"
                            style="color: #ad5389"
                          />
                        </div>
                        <div v-if="car.features[0].seats == '12'">
                          <Icon
                            icon="iconamoon:number-12-circle"
                            width="40"
                            style="color: #ad5389"
                          />
                        </div>
                      </div> -->
                      <div v-if="car.features[0].petrol">
                        <v-col class="d-flex align-center mx-auto">
                          <div v-if="car.features[0].petrol == '95'">
                            <v-img
                              src="../assets/images/95.png"
                              style="margin-left: 1% !important"
                              width="30px"
                            ></v-img>
                          </div>
                          <div v-if="car.features[0].petrol == '97'">
                            <v-img
                              src="../assets/images/97.png"
                              style="margin-left: 1% !important"
                              width="30px"
                            ></v-img>
                          </div>
                          <div v-if="car.features[0].petrol == '99'">
                            <v-img
                              src="../assets/images/97.png"
                              style="margin-left: 1% !important"
                              width="30px"
                            ></v-img>
                          </div>
                          <div v-if="car.features[0].petrol == '100'">
                            <v-img
                              src="../assets/images/100.png"
                              style="margin-left: 1% !important"
                              width="30px"
                            ></v-img>
                          </div>
                          <div v-if="car.features[0].petrol == 'Diesel'">
                            <v-img
                              src="../assets/images/diesel.png"
                              style="margin-left: 1% !important"
                              width="30px"
                            ></v-img>
                            <!-- <Icon
                              icon="bi:fuel-pump-diesel"
                              width="30"
                              style="color: #ad5389"
                            /> -->
                          </div>
                        </v-col>
                      </div>
                      <div v-if="car.features[0].gps">
                        <v-col class="d-flex align-center mx-auto">
                          <div v-if="car.features[0].gps == 'true'">
                            <v-img
                              src="../assets/images/gps.png"
                              style="margin-left: 1% !important"
                              width="30px"
                            ></v-img>
                          </div>
                        </v-col>
                      </div>
                      <div v-if="car.features[0].bluetooth">
                        <v-col class="d-flex align-center mx-auto">
                          <div v-if="car.features[0].bluetooth == 'true'">
                            <v-img
                              src="../assets/images/bluetooth.png"
                              style="margin-left: 1% !important"
                              width="30px"
                            ></v-img>
                          </div>
                        </v-col>
                      </div>
                      <div v-if="car.features[0].usb">
                        <v-col class="d-flex align-center mx-auto">
                          <div v-if="car.features[0].usb == 'true'">
                            <v-img
                              src="../assets/images/usb.png"
                              style="margin-left: 1% !important"
                              width="30px"
                            ></v-img>
                          </div>
                        </v-col>
                      </div>
                      <div v-if="car.features[0].aux">
                        <v-col class="d-flex align-center mx-auto">
                          <div v-if="car.features[0].aux == 'true'">
                            <v-img
                              src="../assets/images/audio-cable.png"
                              style="margin-left: 1% !important"
                              width="30px"
                            ></v-img>
                          </div>
                        </v-col>
                      </div>
                      <div v-if="car.features[0].smoking">
                        <v-col class="d-flex align-center mx-auto">
                          <div v-if="car.features[0].smoking == 'false'">
                            <v-img
                              src="../assets/images/smoking.png"
                              style="margin-left: 1% !important"
                              width="30px"
                            ></v-img>
                          </div>
                        </v-col>
                      </div>
                    </v-row>
                    <!-- </div> -->
                  </v-card>
                </v-container>
              </v-tab-item>
              <v-tab-item
                v-for="(categoryCars, categoryName) in groupedCars"
                :key="categoryName"
                :value="categoryName"
              >
                <v-container>
                  <v-card
                    elevation="5"
                    class="grid4"
                    v-for="car in categoryCars"
                    :key="car._id"
                    style="padding-bottom: 1%; margin-top: 20px !important"
                  >
                    <!-- <v-card class="grid5" :elevation="5" shaped> -->
                    <v-row
                      class="d-flex align-center justify-center"
                      style="margin-top: 20px !important"
                    >
                      <div class="text-center mx-auto text4">
                        {{ car.make }} {{ car.model }}
                      </div>
                    </v-row>
                    <!-- </v-card> -->
                    <!-- <v-spacer></v-spacer><v-spacer></v-spacer> -->
                    <v-card
                      class="grid4"
                      :elevation="5"
                      shaped
                      style="
                        margin-top: 20px !important ;
                        border-radius: inherit !important;
                      "
                    >
                      <v-row class="d-flex align-center justify-center">
                        <v-btn
                          aria-label="Whatsapp"
                          class="justify-end btnwa2"
                          :href="`https://wa.me/126565477?text=I'm%20interested%20in%20your%20${car.make}%20${car.model}%20for%20rental%20from%20JRV%20Rental%20Services%20Seremban`"
                          target="_blank"
                          @click="
                            trackButtonClick('WhatsappMessageInactive', car)
                          "
                          ><v-icon
                            style="color: white !important"
                            alt="WhatsApp icon"
                            aria-label="Contact JRV-Car-Rental-Seremban on WhatsApp"
                            >mdi-whatsapp</v-icon
                          ><v-card-text class="text-center header3"
                            >Book Now</v-card-text
                          ></v-btn
                        >
                      </v-row>
                    </v-card>
                    <carousel-3d>
                      <slide
                        v-for="(image, i) in car.imageUrl"
                        :index="i"
                        :key="i"
                        style="
                          max-width: 250px !important;
                          max-height: 250px !important;
                        "
                      >
                        <template
                          slot-scope="{
                            index,
                            isCurrent,
                            leftIndex,
                            rightIndex,
                          }"
                        >
                          <img
                            :data-index="index"
                            :class="{
                              current: isCurrent,
                              onLeft: leftIndex >= 0,
                              onRight: rightIndex >= 0,
                            }"
                            :src="image.url"
                            width="250px"
                            height="250px"
                          />
                        </template>
                      </slide>
                    </carousel-3d>
                    <v-row align="center" class="d-flex align-center">
                      <v-col>
                        <v-card-text
                          class="d-flex align-center justify-center price"
                        >
                          <textra
                            :data="car.pricePerDay"
                            :timer="3"
                            filter="left-right"
                            infinite
                          />
                        </v-card-text>
                      </v-col>
                    </v-row>
                    <v-row align="center" class="d-flex align-center">
                      <v-col>
                        <v-card-text
                          class="d-flex align-center justify-center price2"
                        >
                          <textra
                            :data="car.pricePer3Days"
                            :timer="3"
                            filter="left-right"
                            infinite
                          />
                        </v-card-text>
                      </v-col>
                    </v-row>
                    <v-row align="center" class="d-flex align-center">
                      <v-col>
                        <v-card-text
                          class="d-flex align-center justify-center price3"
                        >
                          <textra
                            :data="car.pricePerWeek"
                            :timer="3"
                            filter="left-right"
                            infinite
                          />
                        </v-card-text>
                      </v-col>
                    </v-row>
                    <v-row align="center" class="d-flex align-center">
                      <v-col>
                        <v-card-text
                          class="d-flex align-center justify-center price4"
                        >
                          <textra
                            :data="car.pricePerMonth"
                            :timer="3"
                            filter="left-right"
                            infinite
                          />
                        </v-card-text>
                      </v-col>
                    </v-row>
                    <v-row align="center" class="d-flex align-center">
                      <v-col class="d-flex align-center mx-auto">
                        <v-card-text class="text-center mx-auto color"
                          >Available Colors :
                        </v-card-text>
                        <v-card
                          v-for="color in car.colors"
                          :key="color"
                          min-height="20px"
                          min-width="20px"
                          :style="{ backgroundColor: color }"
                          style="
                            margin-left: 2px !important;
                            margin-right: 2px !important;
                          "
                        >
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row
                      align="center"
                      class="d-flex align-center"
                      style="margin-right: -12px !important"
                    >
                      <!-- <div class="d-flex align-items-center justify-content-center"> -->
                      <div v-if="car.features[0].type">
                        <v-col class="d-flex align-center mx-auto">
                          <div v-if="car.features[0].type == 'SEDAN'">
                            <v-img
                              src="../assets/images/sedan.png"
                              style="margin-left: 1% !important"
                              width="40px"
                            ></v-img>
                          </div>
                          <div v-if="car.features[0].type == 'HATCHBACK'">
                            <v-img
                              src="../assets/images/hatchback.png"
                              style="margin-left: 1% !important"
                              width="40px"
                            ></v-img>
                          </div>
                          <div v-if="car.features[0].type == 'SUV'">
                            <v-img
                              src="../assets/images/suv.png"
                              style="margin-left: 1% !important"
                              width="40px"
                            ></v-img>
                          </div>
                          <div v-if="car.features[0].type == 'MPV'">
                            <v-img
                              src="../assets/images/minivan.png"
                              style="margin-left: 1% !important"
                              width="40px"
                            ></v-img>
                          </div>
                        </v-col>
                      </div>
                      <div v-if="car.features[0].transmission">
                        <v-col class="d-flex align-center mx-auto">
                          <div v-if="car.features[0].transmission == 'Auto'">
                            <v-img
                              src="../assets/images/auto.png"
                              style="margin-left: 1% !important"
                              width="30px"
                            ></v-img>
                          </div>
                          <div v-if="car.features[0].transmission == 'Manual'">
                            <v-img
                              src="../assets/images/manual.png"
                              style="margin-left: 1% !important"
                              width="30px"
                            ></v-img>
                          </div>
                        </v-col>
                      </div>
                      <!-- <div v-if="car.features[0].seats">
                        <div v-if="car.features[0].seats == '5'">
                          <Icon
                            icon="iconamoon:number-5-circle"
                            width="40"
                            style="color: #ad5389"
                          />
                        </div>
                        <div v-if="car.features[0].seats == '7'">
                          <Icon
                            icon="iconamoon:number-7-circle"
                            width="40"
                            style="color: #ad5389"
                          />
                        </div>
                        <div v-if="car.features[0].seats == '12'">
                          <Icon
                            icon="iconamoon:number-12-circle"
                            width="40"
                            style="color: #ad5389"
                          />
                        </div>
                      </div> -->
                      <div v-if="car.features[0].petrol">
                        <v-col class="d-flex align-center mx-auto">
                          <div v-if="car.features[0].petrol == '95'">
                            <v-img
                              src="../assets/images/95.png"
                              style="margin-left: 1% !important"
                              width="30px"
                            ></v-img>
                          </div>
                          <div v-if="car.features[0].petrol == '97'">
                            <v-img
                              src="../assets/images/97.png"
                              style="margin-left: 1% !important"
                              width="30px"
                            ></v-img>
                          </div>
                          <div v-if="car.features[0].petrol == '99'">
                            <v-img
                              src="../assets/images/97.png"
                              style="margin-left: 1% !important"
                              width="30px"
                            ></v-img>
                          </div>
                          <div v-if="car.features[0].petrol == '100'">
                            <v-img
                              src="../assets/images/100.png"
                              style="margin-left: 1% !important"
                              width="30px"
                            ></v-img>
                          </div>
                          <div v-if="car.features[0].petrol == 'Diesel'">
                            <v-img
                              src="../assets/images/diesel.png"
                              style="margin-left: 1% !important"
                              width="30px"
                            ></v-img>
                          </div>
                        </v-col>
                      </div>
                      <div v-if="car.features[0].gps">
                        <v-col class="d-flex align-center mx-auto">
                          <div v-if="car.features[0].gps == 'true'">
                            <v-img
                              src="../assets/images/gps.png"
                              style="margin-left: 1% !important"
                              width="30px"
                            ></v-img>
                          </div>
                        </v-col>
                      </div>
                      <div v-if="car.features[0].bluetooth">
                        <v-col class="d-flex align-center mx-auto">
                          <div v-if="car.features[0].bluetooth == 'true'">
                            <v-img
                              src="../assets/images/bluetooth.png"
                              style="margin-left: 1% !important"
                              width="30px"
                            ></v-img>
                          </div>
                        </v-col>
                      </div>
                      <div v-if="car.features[0].usb">
                        <v-col class="d-flex align-center mx-auto">
                          <div v-if="car.features[0].usb == 'true'">
                            <v-img
                              src="../assets/images/usb.png"
                              style="margin-left: 1% !important"
                              width="30px"
                            ></v-img>
                          </div>
                        </v-col>
                      </div>
                      <div v-if="car.features[0].aux">
                        <v-col class="d-flex align-center mx-auto">
                          <div v-if="car.features[0].aux == 'true'">
                            <v-img
                              src="../assets/images/audio-cable.png"
                              style="margin-left: 1% !important"
                              width="30px"
                            ></v-img>
                          </div>
                        </v-col>
                      </div>
                      <div v-if="car.features[0].smoking">
                        <v-col class="d-flex align-center mx-auto">
                          <div v-if="car.features[0].smoking == 'false'">
                            <v-img
                              src="../assets/images/smoking.png"
                              style="margin-left: 1% !important"
                              width="30px"
                            ></v-img>
                          </div>
                        </v-col>
                      </div>
                    </v-row>
                    <!-- </div> -->
                  </v-card>
                </v-container>
              </v-tab-item>
            </v-tabs-items>
          </v-tab-item>
          <v-tab-item style="margin-top: 20px !important">
            <!-- <v-form ref="form" @submit.prevent="submitReview">
              <v-card style="background-color: transparent !important">
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <v-card-text
                        class="d-flex align-center justify-center about"
                        >Submit A Review</v-card-text
                      ></v-expansion-panel-header
                    >
                    <v-expansion-panel-content>
                      <v-container>
                        <v-row>
                          <v-col cols="12" md="4">
                            <v-card-text
                              class="d-flex align-center justify-center text2"
                              >Name</v-card-text
                            >
                            <v-text-field
                              dark
                              v-model="review.name"
                              :rules="[rules.required]"
                              required
                              class="text"
                              dense
                              solo
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-card-text
                              class="d-flex align-center justify-center text2"
                              >Car Rented</v-card-text
                            >
                            <v-select
                              required
                              v-model="review.car"
                              :items="generateCombinedModels"
                              dense
                              dark
                              solo
                            ></v-select>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-card-text
                              class="d-flex align-center justify-center text2"
                              >Rating 1-5</v-card-text
                            >
                            <v-rating
                              v-model="review.rating"
                              hover
                              style="background-color: #0000003d !important"
                              :value="3"
                              color="#80ff00 "
                              :rules="[rules.required]"
                              half-increments
                              requiree
                              dark
                              class="text-center mx-auto"
                            ></v-rating>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="4">
                            <v-card-text
                              class="d-flex align-center justify-center text2"
                              >Review (maximum 150 words)</v-card-text
                            >
                            <v-textarea
                              v-model="review.text"
                              :rules="reviewRules"
                              :counter="150"
                              required
                              dark
                              class="text"
                              dense
                              solo
                            ></v-textarea>
                          </v-col>
                        </v-row>
                        <v-row class="justify-center align-center" no-gutters>
                          <v-btn class="button" dark type="submit">
                            <v-icon color="#fff"> mdi-send mdi-18px </v-icon>
                            Submit
                          </v-btn>
                        </v-row>
                      </v-container>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card>
            </v-form> -->
            <v-container>
              <v-card-text
                class="d-flex align-center justify-center about"
                style="margin-top: 2% !important"
                >Reviews By Our Customers</v-card-text
              >

              <!-- <div id="trustmary-widget"></div> -->
              <div>
                <script
                  defer
                  type="application/javascript"
                  src="https://widget.trustmary.com/yhomYELyi"
                ></script>
              </div>
              <!-- <v-row>
                <v-col
                  v-for="(review, index) in currentReviews"
                  :key="index"
                  cols="12/3"
                  md="4"
                >
                  <v-card class="my-card">
                    <v-rating
                      :value="review.rating"
                      readonly
                      :color="'#7A3F75 !important'"
                      half-increments
                      class="text-center mx-auto"
                    ></v-rating>
                    <v-card-text class="text-center mx-auto text5">{{
                      review.name
                    }}</v-card-text>
                    <v-card-text class="text-center mx-auto text6"
                      >"{{ review.text }}"</v-card-text
                    >
                  </v-card>
                </v-col>
              </v-row>
              <v-pagination
                dark
                v-model="currentPage"
                :total-visible="5"
                :length="totalPages"
                @input="pageChanged"
                :disabled="disabled"
              /> -->
            </v-container>
          </v-tab-item>
          <v-tab-item style="margin-top: 20px !important">
            <v-row align="center" style="margin-top: 2% !important">
              <v-card-text class="text-center mx-auto headercu">
                <!-- <v-col :cols="4" align="center"> -->
                <a
                  href="tel:+60126565477"
                  target="_blank"
                  class="text2 text-decoration-none"
                >
                  <v-icon
                    aria-label="phone"
                    size="24"
                    style="margin-right: 0 !important; background-image: linear-gradient(to right, #ad5389, #3c1053) !important;
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,"
                    >mdi-phone</v-icon
                  >
                  <span class="pl-2">: +60 12-656 5477</span>
                </a>
              </v-card-text>
              <!-- </v-col> -->
              <!-- <v-col :cols="4" align="center"> -->
            </v-row>
            <v-row align="center" style="margin-top: 2% !important">
              <v-card-text class="text-center mx-auto headercu">
                <a
                  href="tel:+60138845477"
                  target="_blank"
                  class="text2 text-decoration-none"
                >
                  <v-icon
                    aria-label="phone"
                    size="24"
                    style="margin-right: 0 !important; background-image: linear-gradient(to right, #ad5389, #3c1053) !important;
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,"
                    >mdi-cellphone</v-icon
                  >
                  <span class="pl-2">: +60 13-884 5477</span>
                </a>
              </v-card-text>
              <!-- </v-col> -->
              <!-- <v-col :cols="4" align="center"> -->
            </v-row>
            <v-row align="center" style="margin-top: 2% !important">
              <v-card-text class="text-center mx-auto headercu">
                <a
                  href="mailto:jrvservices.main@gmail.com"
                  target="_blank"
                  class="text2 text-decoration-none"
                >
                  <v-icon
                    aria-label="email"
                    size="24"
                    style="margin-right: 0 !important; background-image: linear-gradient(to right, #ad5389, #3c1053) !important;
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,"
                    >mdi-gmail</v-icon
                  >
                  <span class="pl-2">: jrvservices.main@gmail.com</span>
                </a>
              </v-card-text>
              <!-- </v-col> -->
            </v-row>
            <v-row align="center" style="margin-top: 2% !important">
              <a
                href="https://maps.app.goo.gl/DYyk2Wh1xDtYCHq28"
                target="_blank"
                class="text2 text-decoration-none"
              >
                <v-card-text class="text-center mx-auto headercu"
                  ><v-icon
                    aria-label="location"
                    size="24"
                    style="margin-right: 0 !important; background-image: linear-gradient(to right, #ad5389, #3c1053) !important;
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,"
                    >mdi-map-marker</v-icon
                  >
                  : Lot 12071, Jalan Sungai Ujong, Taman Ast, 70200 Seremban, Negeri Sembilan (JRV Car Wash)
                </v-card-text>
              </a>
            </v-row>
            <v-row align="center" style="margin-top: 2% !important">
              <v-col align="center">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3985.3141632331017!2d101.92937607452905!3d2.722755855626687!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cde94c7dd2d7f1%3A0xd8e6033569306cdd!2sJRV%20Kereta%20Sewa%20Seremban!5e0!3m2!1sen!2smy!4v1706678957873!5m2!1sen!2smy"
                  frameborder="0"
                  style="
                    width: 300px;
                    height: 300px;
                    max-width: 100%;
                    margin-bottom: 1%;
                  "
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
      <v-footer padless class="footer" color="transparent">
        <v-card-text class="d-flex flex-column align-top footer">
          <div class="footer-top mb-3">
            <v-row align="center" class="row2">
              <v-col>
                <span
                  class="mr-3 d-grid align-center justify-center"
                  style="text-align: justify"
                >
                  <a
                    href="https://jrvservices.co/"
                    target="_blank"
                    class="text2 text-decoration-none"
                  >
                    <v-card-text
                      class="d-flex align-center justify-center text2"
                      >JRV Car Rental Seremban | Kereta Sewa
                      Seremban JRV</v-card-text
                    >
                  </a>
                </span>
              </v-col>
            </v-row>
            <v-row align="center" class="row2">
              <v-col>
                <a
                  href="https://www.facebook.com/profile.php?id=100095115500120"
                  target="_blank"
                >
                  <v-icon
                    aria-label="Facebook"
                    size="32"
                    class="mx-2 btnWa"
                    style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053);
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
                    @click="trackButtonClick('FacebookGroup')"
                    >mdi-facebook</v-icon
                  >
                </a>
                <a
                  href="https://www.instagram.com/jrv_keretasewa/"
                  target="_blank"
                >
                  <v-icon
                    aria-label="Instagram"
                    size="32"
                    class="mx-2 btnWa"
                    style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053);
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
                    @click="trackButtonClick('InstagramGroup')"
                    >mdi-instagram</v-icon
                  >
                </a>
                <a
                  href="https://chat.whatsapp.com/DVZXvnBiDqS0Z9jkHwL9yT"
                  target="_blank"
                >
                  <v-icon
                    aria-label="Whatsapp"
                    size="32"
                    class="mx-2 btnWa"
                    style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053);
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
                    @click="trackButtonClick('WhatsappGroup')"
                    >mdi-whatsapp</v-icon
                  >
                </a>
                <a href="https://t.me/+ILGvKb39imRkMDNl" target="_blank">
                  <v-icon
                    aria-label="Telegram"
                    size="32"
                    class="mx-2 btnWa"
                    style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053);
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
                    @click="trackButtonClick('TelegramGroup')"
                    >mdi-telegram</v-icon
                  >
                </a>
              </v-col>
            </v-row>
          </div>
          <div
            class="footer-bottom text2"
            style="margin-top: 1% !important ; margin-bottom: 1% !important"
          >
            Copyright {{ new Date().getFullYear() }} @
            <a
              href="https://www.linkedin.com/in/rajveenz2/"
              target="_blank"
              class="company-link"
              style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053);
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
              >JRV GLOBAL SERVICES</a
            >
          </div>
        </v-card-text>
      </v-footer>
    </v-container>
  </v-app>
</template>

<!-- <script src="https://widget.trustmary.com/yhomYELyi"></script> -->
<script>
import DataService from "../services/dataService.js";
const dataService = new DataService();
import store from "./../app.store";
// import { Icon } from "@iconify/vue2";
// import fuelPumpDiesel from "@iconify/icons-bi/fuel-pump-diesel";

export default {
  // components: {
  //   Icon,
  // },
  data() {
    return {
      // icons: {
      //   fuelPumpDiesel,
      // },
      // name : this.$store.state.userProfile.name
      tab: "LOCAL",
      tab1: "OUR CARS",
      showPopup: true,
      popupTitle: "Missing Documents",
      popupMessage:
        "Please upload or fix the missing documents sent on email to avoid booking issues.",
      showAdminButton: false,
      bookingDate: null,
      returnDate: null,
      user: [],
      userProfile: [],
      cars: [],
      reviews: [],
      reviewRules: [
        (value) => {
          if (value) return true;

          return "Review is requred.";
        },
        (value) => {
          if (value?.length <= 150) return true;

          return "Review must be less than 150 characters.";
        },
      ],
      currentCarIndex: 0,
      activeSlide: 0,
      review: {
        car: "",
        name: "",
        rating: 0,
        text: "",
      },
      ratings: [1, 2, 3, 4, 5],
      rules: {
        required: (value) => !!value || "This field is required.",
      },
      currentPage: 1,
      itemsPerPage: 3,
      disabled: false,
      availableCars: [],
    };
  },

  mounted: function () {
    this.getUser();
    this.getCars();
    // this.getReviews();
    // setInterval(() => {
    //   this.nextSlide();
    // }, 20000);
  },

  computed: {
    generateCombinedModels() {
      let CombinedModels = this.cars.map((car) => car.make + " " + car.model);
      return CombinedModels;
    },
    groupedCars() {
      const categoryOrder = [
        "LOCAL",
        "MPV & VAN",
        "SUV",
        "LUXURY SEDAN",
        "PREMIUM",
        "4WD DRIVE VEHICLES",
        "2 DOOR SPORTS COUPE",
        "SUPERCAR",
      ];
      const grouped = this.cars.reduce((grouped, car) => {
        let c = car.category.toUpperCase();
        if (!grouped[c]) {
          grouped[c] = [];
        }
        const existingCarIndex = grouped[c].findIndex(
          (existingCar) =>
            existingCar.make === car.make && existingCar.model === car.model
        );
        if (existingCarIndex >= 0) {
          // Car already exists in group, add color to existing car's color array
          const existingCar = grouped[c][existingCarIndex];
          if (!existingCar.colors.includes(car.color)) {
            existingCar.colors.push(car.color);
          }
        } else {
          // Car does not exist in group, add it
          grouped[c].push({
            make: car.make,
            model: car.model,
            colors: [car.color],
            imageUrl: car.imageUrl,
            pricePerDay: ["RM " + car.pricePerDay.toString() + " CAR RENTAL DAILY"],
            pricePer3Days: ["RM " + car.pricePer3days.toString() + " CAR RENTAL FOR 3 DAYS"],
            pricePerWeek: ["RM " + car.pricePerWeek.toString() + " CAR RENTAL WEEKLY"],
            pricePerMonth: ["RM " + car.pricePerMonth.toString() + " CAR RENTAL MONTHLY"],
            desc: car.desc,
            features: car.features,
          });
        }
        return grouped;
      }, {});
      return Object.keys(grouped)
        .sort((a, b) => categoryOrder.indexOf(a) - categoryOrder.indexOf(b))
        .reduce((sorted, category) => {
          sorted[category] = grouped[category].sort((carA, carB) => {
            const priceA = parseInt(carA.pricePerDay[0].split(" ")[1]);
            const priceB = parseInt(carB.pricePerDay[0].split(" ")[1]);
            return priceA - priceB;
          });
          return sorted;
        }, {});
    },

    chunkedReviews() {
      const chunkedArray = [];
      const reviewsCopy = [...this.reviews];
      while (reviewsCopy.length > 0) {
        chunkedArray.push(reviewsCopy.splice(0, this.itemsPerPage));
      }
      return chunkedArray;
    },
    currentReviews() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.reviews.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.reviews.length / this.itemsPerPage);
    },
  },

  methods: {
    // initializeTrustmaryWidget() {
    //   // Replace the 'yourTrustmaryWidgetId' with the actual Trustmary widget ID provided by Trustmary
    //   const widgetId = "yhomYELyi";

    //   // Customize widget options according to your preferences
    //   const widgetOptions = {
    //     // Specify options here, such as the widget width, height, color scheme, etc.
    //   };

    //   // Initialize the Trustmary widget
    //   window.TrustmaryWidgets?.google?.showGoogleReviews(
    //     widgetId,
    //     widgetOptions,
    //     this.$refs.trustmaryContainer
    //   );
    // },

    async trackButtonClick(a, car, mobileNumber) {
      console.log(a);
      console.log(car);
      console.log(mobileNumber);
      if (car && mobileNumber) {
        if (car.make) {
          await dataService.addTracker({
            name: "Clicks",
            buttonName: a,
            dataType: car.make + " " + car.model,
            mobileNumber: mobileNumber,
          });
        } else {
          await dataService.addTracker({
            name: "Clicks",
            buttonName: a,
            dataType: car,
            mobileNumber: mobileNumber,
          });
        }
      }
      if (car && !mobileNumber) {
        if (car.make) {
          await dataService.addTracker({
            name: "Clicks",
            buttonName: a,
            dataType: car.make + " " + car.model,
            mobileNumber: "jrvservices.co",
          });
        } else {
          await dataService.addTracker({
            name: "Clicks",
            buttonName: a,
            dataType: car,
            mobileNumber: "jrvservices.co",
          });
        }
      }
      if (!car && !mobileNumber) {
        await dataService.addTracker({
          name: "Clicks",
          buttonName: a,
          dataType: "Groups",
          mobileNumber: "jrvservices.co",
        });
      }
    },

    async getUser() {
      this.$setLoader();
      console.log(process.env.NODE_ENV);
      if (process.env.NODE_ENV != "development") {
        await dataService.addTracker({
          name: "Site Loaded",
          buttonName: "Page Click",
          dataType: "jrvservices.co",
          mobileNumber: "Null",
        });
      }
      window.$cookies.set("posToken", window.$cookies.get("posToken"));
      if (window.$cookies.get("posToken") !== "null") {
        store.state.loader = true;
        dataService
          .whoAmI()
          .then((res) => {
            this.user = res.data.localUser;
            if (this.user.roles[0] == "admin") {
              this.showAdminButton = true;
            }
            this.userProfile = res.data.user;
            store.state.loader = false;
          })
          .catch(() => {
            window.location.reload();
          });
      } else {
        store.state.loader = false;
      }

      this.$disableLoader();
    },
    async getCars() {
      this.$setLoader();
      await dataService.getCars().then((res) => {
        for (let car of res.data.cars) {
          if (car.rentStatus != "Inactive") {
            car.imageUrl[4] = car.imageUrl[1];
            this.cars.push(car);
          }
        }
      });
      await dataService.getAvailableCars().then((res) => {
        for (let car of res.data.availableCars) {
          if (car.rentStatus != "Inactive") {
            car.imageUrl[4] = car.imageUrl[1];
            (car.pricePerDay = ["RM " + car.pricePerDay.toString() + " CAR RENTAL DAILY"]),
              (car.pricePer3Days = [
                "RM " + car.pricePer3days.toString() + " CAR RENTAL 3 DAYS",
              ]),
              (car.pricePerWeek = [
                "RM " + car.pricePerWeek.toString() + " CAR RENTAL WEEKLY",
              ]),
              (car.pricePerMonth = [
                "RM " + car.pricePerMonth.toString() + " CAR RENTAL MONTHLY",
              ]),
              this.availableCars.push(car);
          }
        }
      });

      this.$disableLoader();
    },

    async getReviews() {
      this.$setLoader();
      await dataService.getReviews().then((res) => {
        this.reviews = res.data.reviews;
      });

      this.$disableLoader();
    },

    pageChanged(pageNumber) {
      this.currentPage = pageNumber;
    },

    async submitReview() {
      this.$setLoader();
      const isValid = await this.$refs.form.validate();
      if (isValid) {
        await dataService.addReview(this.review).then((res) => {
          console.log(res);
          this.$refs.form.reset();
          window.location.reload();
        });

        this.$disableLoader();
      } else {
        alert("Please fill out all required fields");
        this.$disableLoader();
      }
    },

    account() {
      this.$router.push({ path: `/edit` });
    },

    logout() {
      window.getApp.$emit("APP_LOGOUT");
    },
  },
};
</script>

<style scoped lang="scss">
#particles-js {
  background-color: #f9ece0;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
}
.container {
  /* margin-top: 5%; */
  height: fit-content !important;
}

.nav-items {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    padding: 0 10px;
  }
}
i {
  display: none;
}

.v-application .elevation-8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.2), 0px 3px 14px 2px rgba(0, 0, 0, 0.2) !important;
}

.v-application .elevation-12 {
  box-shadow: 0px 5px 5px -3px #00000049, 0px 8px 10px 1px #0000004d,
    0px 3px 14px 2px #00000054 !important;
}

.my-card {
  height: 240px !important;
  background-color: transparent !important;
}

.row-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
